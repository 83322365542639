<template>
  <v-img
    v-if="namespace === 'Home'"
    :height="$vuetify.breakpoint.xsOnly ? '100vh' : '100vh'"
    gradient="to left, rgba(255, 255, 255, .55), rgba(255, 255, 255, .9)"
    src="/static/hero.jpg"
  >
    <v-container
      fill-height
      fluid
      :px-12="$vuetify.breakpoint.mdAndUp"
    >
      <v-row align-center>
        <v-slide-x-transition appear>
          <v-col>
            <v-img
              max-width="35%"
              src="/static/ps-logo.png"
            />
            <br>
            <!-- <h1
              class="text-uppercase font-weight-bold mb-4"
              style="font-family:Genos !important;"
              :class="[$vuetify.breakpoint.xsOnly ? 'display-1' : 'display-3']"
            >
              POLGRID SERWIS
            </h1> -->

            <div
              class="mb-4 text-uppercase"
              style="font-family: 'Montserrat', sans-serif !important;"
              :class="[$vuetify.breakpoint.xsOnly ? 'headline' : 'display-1 font-weight-light']"
            >
              energetyka | gazownictwo
            </div>

            <v-responsive
              max-width="600"
              class="mb-5"
              style="font-family: 'Montserrat', sans-serif !important;"
            >
              CERTAINTY OF MAINTENANCE
            </v-responsive>
          </v-col>
        </v-slide-x-transition>
      </v-row>
    </v-container>
  </v-img>

  <v-sheet
    v-else
    :min-height="$vuetify.breakpoint.smAndDown ? '45vh' : '300px'"
    color="grey lighten-3"
    height="20vh"
  >
    <v-container fill-height>
      <v-row
        align-content="center"
        justify="center"
        class="fill-height"
      >
        <v-col cols="12">
          <h1
            class="display-2 text-center"
            v-text="title"
          />
        </v-col>

        <v-breadcrumbs
          :items="breadcrumbs"
          class="grey--text pb-0 px-3"
        />
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
  export default {
    computed: {
      breadcrumbs () {
        const home = { text: 'STRONA GŁÓWNA', to: '/' }

        switch (this.namespace) {
          case 'About': return [home, { text: 'O FIRMIE' }]
          case 'Contact': return [home, { text: 'KONTAKT' }]
          default: return []
        }
      },
      namespace () {
        return this.$route.name
      },
      title () {
        switch (this.namespace) {
          case 'About': return 'XTERRA DEVELOPMENT'
          case 'Contact': return 'KONTAKT'
          default: return ''
        }
      }
    }
  }
</script>
